export const baseUrl =
  process.env.NODE_ENV === 'asdf'
    ? 'http://localhost:3001'
    : process.env.REACT_APP_API_BASE_URL;
export const headers = {
  'Content-Type': 'application/json',
  authorization: 'allow',
  'Access-Control-Allow-Origin': 'True',
};

export const validEmail =
  /^[a-zA-Z0-9-\._:$!%']+@[a-zA-Z0-9\.-]*(?:nhs|adviseinc|hscni|nhs.scot|gardiner|efficioconsulting|priory|htepg|pwc|crowncommercial.gov)\.[a-zA-Z\\.]{2,5}$/; // eslint-disable-line
export const validEmailHelp =
  'Email must be a valid NHS or HSCNI email in the form xxx@xxx.xxx.';

export const organisations = [
  {
    id: 1,
    name: 'Airedale NHS Foundation Trust | RCF',
    crm_code: '17002920826',
    crm_name: 'Airedale NHS Foundation Trust | RCF',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 2,
    name: "ALDER HEY CHILDREN'S NHS FOUNDATION TRUST | RBS",
    crm_code: '17002920827',
    crm_name: "Alder Hey Children's NHS Foundation Trust | RBS",
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 3,
    name: 'Aneurin Bevan University Lhb | 7A6',
    crm_code: '17002920828',
    crm_name: 'Aneurin Bevan University LHB | 7A6',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 4,
    name: "ASHFORD AND ST PETER'S HOSPITALS NHS FOUNDATION TRUST | RTK",
    crm_code: '17002920829',
    crm_name: "Ashford and St Peter's Hospitals NHS Foundation Trust | RTK",
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 5,
    name: 'Avon And Wiltshire Mental Health Partnership NHS Trust | RVN',
    crm_code: '17002920830',
    crm_name: 'Avon and Wiltshire Mental Health Partnership NHS Trust | RVN',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 6,
    name: 'Barking, Havering And Redbridge University Hospitals NHS Trust | RF4',
    crm_code: '17002920831',
    crm_name:
      'Barking, Havering and Redbridge University Hospitals NHS Trust | RF4',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 7,
    name: 'Barnet, Enfield And Haringey Mental Health NHS Trust | RRP',
    crm_code: '17002920832',
    crm_name: 'Barnet, Enfield and Haringey Mental Health NHS Trust | RRP',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 8,
    name: 'Barnsley Hospital NHS Foundation Trust | RFF',
    crm_code: '17002920833',
    crm_name: 'Barnsley Hospital NHS Foundation Trust | RFF',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 9,
    name: 'Barts Health NHS Trust | R1H',
    crm_code: '17002920834',
    crm_name: 'Barts Health NHS Trust | R1H',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 10,
    name: 'Bedfordshire Hospitals NHS Foundation Trust | RC9',
    crm_code: '17002920913',
    crm_name: 'Bedfordshire Hospitals NHS Foundation Trust | RC9',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 11,
    name: 'Belfast Health & Social Care Trust | ZT1',
    crm_code: '17002921170',
    crm_name: 'Belfast Health & Social Care Trust | ZT1',
    applications_subscription: 'Price Benchmarking (PB), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 12,
    name: 'Berkshire Healthcare NHS Foundation Trust | RWX',
    crm_code: '17002920835',
    crm_name: 'Berkshire Healthcare NHS Foundation Trust | RWX',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 13,
    name: 'Betsi Cadwaladr University Lhb | 7A1',
    crm_code: '17002920836',
    crm_name: 'Betsi Cadwaladr University LHB | 7A1',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 14,
    name: 'Birmingham And Solihull Mental Health NHS Foundation Trust | RXT',
    crm_code: '17002920837',
    crm_name:
      'Birmingham and Solihull Mental Health NHS Foundation Trust | RXT',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 15,
    name: 'Birmingham Community Healthcare NHS Foundation Trust | RYW',
    crm_code: '17002923059',
    crm_name: 'Birmingham Community Healthcare NHS Foundation Trust | RYW',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 16,
    name: "BIRMINGHAM WOMEN'S AND CHILDREN'S NHS FOUNDATION TRUST | RQ3",
    crm_code: '17002920838',
    crm_name: "Birmingham Women's and Children's NHS Foundation Trust | RQ3",
    applications_subscription:
      'Procurement Dashboard+ (PD+), Platform, Catalogue Check (Lite), Workplan',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 17,
    name: 'Blackpool Teaching Hospitals NHS Foundation Trust | RXL',
    crm_code: '17002920840',
    crm_name: 'Blackpool Teaching Hospitals NHS Foundation Trust | RXL',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 18,
    name: 'Bolton NHS Foundation Trust | RMC',
    crm_code: '17002920841',
    crm_name: 'Bolton NHS Foundation Trust | RMC',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 19,
    name: 'Bradford Teaching Hospitals NHS Foundation Trust | RAE',
    crm_code: '17002920843',
    crm_name: 'Bradford Teaching Hospitals NHS Foundation Trust | RAE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 20,
    name: 'Bridgewater Community Healthcare NHS Foundation Trust | RY2',
    crm_code: '17002923060',
    crm_name: 'Bridgewater Community Healthcare NHS Foundation Trust | RY2',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 21,
    name: 'Brighton And Sussex University Hospitals NHS Trust | RXH',
    crm_code: '17002920844',
    crm_name: 'Brighton and Sussex University Hospitals NHS Trust | RXH',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 22,
    name: 'Buckinghamshire Healthcare NHS Trust | RXQ',
    crm_code: '17002920845',
    crm_name: 'Buckinghamshire Healthcare NHS Trust | RXQ',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 23,
    name: 'Calderdale And Huddersfield NHS Foundation Trust | RWY',
    crm_code: '17002920846',
    crm_name: 'Calderdale and Huddersfield NHS Foundation Trust | RWY',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 24,
    name: 'Cambridge University Hospitals NHS Foundation Trust | RGT',
    crm_code: '17002920847',
    crm_name: 'Cambridge University Hospitals NHS Foundation Trust | RGT',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 25,
    name: 'Cambridgeshire And Peterborough NHS Foundation Trust | RT1',
    crm_code: '17002920848',
    crm_name: 'Cambridgeshire and Peterborough NHS Foundation Trust | RT1',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 26,
    name: 'Cambridgeshire Community Services NHS Trust | RYV',
    crm_code: '17002923061',
    crm_name: 'Cambridgeshire Community Services NHS Trust | RYV',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 27,
    name: 'Cardiff And Vale University Lhb | 7A4',
    crm_code: '17002920850',
    crm_name: 'Cardiff and Vale University LHB | 7A4',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 28,
    name: 'Central And North West London NHS Foundation Trust | RV3',
    crm_code: '17002920851',
    crm_name: 'Central and North West London NHS Foundation Trust | RV3',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 29,
    name: 'Central London Community Healthcare NHS Trust | RYX',
    crm_code: '17002923062',
    crm_name: 'Central London Community Healthcare NHS Trust | RYX',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 30,
    name: 'Chelsea And Westminster Hospital NHS Foundation Trust | RQM',
    crm_code: '17002920852',
    crm_name: 'Chelsea and Westminster Hospital NHS Foundation Trust | RQM',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 31,
    name: 'Cheshire And Wirral Partnership NHS Foundation Trust | RXA',
    crm_code: '17002920853',
    crm_name: 'Cheshire and Wirral Partnership NHS Foundation Trust | RXA',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 32,
    name: 'Chesterfield Royal Hospital NHS Foundation Trust | RFS',
    crm_code: '17002920854',
    crm_name: 'Chesterfield Royal Hospital NHS Foundation Trust | RFS',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 33,
    name: 'Cornwall Partnership NHS Foundation Trust | RJ8',
    crm_code: '17002920856',
    crm_name: 'Cornwall Partnership NHS Foundation Trust | RJ8',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 34,
    name: 'Countess Of Chester Hospital NHS Foundation Trust | RJR',
    crm_code: '17002920857',
    crm_name: 'Countess of Chester Hospital NHS Foundation Trust | RJR',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 35,
    name: 'County Durham And Darlington NHS Foundation Trust | RXP',
    crm_code: '17002920858',
    crm_name: 'County Durham and Darlington NHS Foundation Trust | RXP',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 36,
    name: 'Coventry And Warwickshire Partnership NHS Trust | RYG',
    crm_code: '17002920859',
    crm_name: 'Coventry and Warwickshire Partnership NHS Trust | RYG',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 37,
    name: 'Croydon Health Services NHS Trust | RJ6',
    crm_code: '17002920860',
    crm_name: 'Croydon Health Services NHS Trust | RJ6',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 38,
    name: 'Cumbria, Northumberland, Tyne And Wear NHS Foundation Trust | RX4',
    crm_code: '17002920934',
    crm_name:
      'Cumbria, Northumberland, Tyne and Wear NHS Foundation Trust | RX4',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 39,
    name: 'Cwm Taf Morgannwg University Lhb | 7A5',
    crm_code: '17002920862',
    crm_name: 'Cwm Taf Morgannwg University LHB | 7A5',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 40,
    name: 'Dartford And Gravesham NHS Trust | RN7',
    crm_code: '17002920863',
    crm_name: 'Dartford and Gravesham NHS Trust | RN7',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 41,
    name: 'Derbyshire Community Health Services NHS Foundation Trust | RY8',
    crm_code: '17002923063',
    crm_name: 'Derbyshire Community Health Services NHS Foundation Trust | RY8',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 42,
    name: 'Derbyshire Healthcare NHS Foundation Trust | RXM',
    crm_code: '17002920865',
    crm_name: 'Derbyshire Healthcare NHS Foundation Trust | RXM',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 43,
    name: 'Devon Partnership NHS Trust | RWV',
    crm_code: '17002920866',
    crm_name: 'Devon Partnership NHS Trust | RWV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 44,
    name: 'Doncaster And Bassetlaw Teaching Hospitals NHS Foundation Trust | RP5',
    crm_code: '17002920867',
    crm_name:
      'Doncaster and Bassetlaw Teaching Hospitals NHS Foundation Trust | RP5',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 45,
    name: 'Dorset County Hospital NHS Foundation Trust | RBD',
    crm_code: '17002920868',
    crm_name: 'Dorset County Hospital NHS Foundation Trust | RBD',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 46,
    name: 'Dorset Healthcare University NHS Foundation Trust | RDY',
    crm_code: '17002920869',
    crm_name: 'Dorset Healthcare University NHS Foundation Trust | RDY',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 47,
    name: 'Dudley Integrated Health And Care NHS Trust | RYK',
    crm_code: '17002920870',
    crm_name: 'Dudley and Walsall Mental Health Partnership NHS Trust | RYK',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 48,
    name: 'East And North Hertfordshire NHS Trust | RWH',
    crm_code: '17002920871',
    crm_name: 'East and North Hertfordshire NHS Trust | RWH',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 49,
    name: 'East Cheshire NHS Trust | RJN',
    crm_code: '17002920872',
    crm_name: 'East Cheshire NHS Trust | RJN',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 50,
    name: 'East Kent Hospitals University NHS Foundation Trust | RVV',
    crm_code: '17002920873',
    crm_name: 'East Kent Hospitals University NHS Foundation Trust | RVV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 51,
    name: 'East Lancashire Hospitals NHS Trust | RXR',
    crm_code: '17002920874',
    crm_name: 'East Lancashire Hospitals NHS Trust | RXR',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 52,
    name: 'East London NHS Foundation Trust | RWK',
    crm_code: '17002920875',
    crm_name: 'East London NHS Foundation Trust | RWK',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 53,
    name: 'East Midlands Ambulance Service NHS Trust | RX9',
    crm_code: '17002920876',
    crm_name: 'East Midlands Ambulance Service NHS Trust | RX9',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 54,
    name: 'East Of England Ambulance Service NHS Trust | RYC',
    crm_code: '17002920877',
    crm_name: 'East of England Ambulance Service NHS Trust | RYC',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 55,
    name: 'East Suffolk And North Essex NHS Foundation Trust | RDE',
    crm_code: '17002920855',
    crm_name: 'East Suffolk and North Essex NHS Foundation Trust | RDE',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 56,
    name: 'East Sussex Healthcare NHS Trust | RXC',
    crm_code: '17002920878',
    crm_name: 'East Sussex Healthcare NHS Trust | RXC',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 57,
    name: 'Epsom And St Helier University Hospitals NHS Trust | RVR',
    crm_code: '17002920879',
    crm_name: 'Epsom and St Helier University Hospitals NHS Trust | RVR',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 58,
    name: 'Essex Partnership University NHS Foundation Trust | R1L',
    crm_code: '17002920821',
    crm_name: 'Essex Partnership University NHS Foundation Trust | R1L',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 59,
    name: 'Frimley Health NHS Foundation Trust | RDU',
    crm_code: '17002920880',
    crm_name: 'Frimley Health NHS Foundation Trust | RDU',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 60,
    name: 'Gateshead Health NHS Foundation Trust | RR7',
    crm_code: '17002920881',
    crm_name: 'Gateshead Health NHS Foundation Trust | RR7',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 61,
    name: 'George Eliot Hospital NHS Trust | RLT',
    crm_code: '17002920882',
    crm_name: 'George Eliot Hospital NHS Trust | RLT',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 62,
    name: 'Gloucestershire Health And Care NHS Foundation Trust | RTQ',
    crm_code: '17002920824',
    crm_name: 'Gloucestershire Health and Care NHS Foundation Trust | RTQ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 63,
    name: 'Gloucestershire Hospitals NHS Foundation Trust | RTE',
    crm_code: '17002920883',
    crm_name: 'Gloucestershire Hospitals NHS Foundation Trust | RTE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 64,
    name: 'Great Ormond Street Hospital For Children NHS Foundation Trust | RP4',
    crm_code: '17002920884',
    crm_name:
      'Great Ormond Street Hospital for Children NHS Foundation Trust | RP4',
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 65,
    name: 'Great Western Hospitals NHS Foundation Trust | RN3',
    crm_code: '17002920885',
    crm_name: 'Great Western Hospitals NHS Foundation Trust | RN3',
    applications_subscription:
      'Procurement Dashboard+ (PD+), Platform, Catalogue Assign (Medium)',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 66,
    name: 'Greater Manchester Mental Health NHS Foundation Trust | RXV',
    crm_code: '17002920886',
    crm_name: 'Greater Manchester Mental Health NHS Foundation Trust | RXV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 67,
    name: "GUY'S AND ST THOMAS' NHS FOUNDATION TRUST | RJ1",
    crm_code: '17002920887',
    crm_name: "Guy's and St Thomas' NHS Foundation Trust | RJ1",
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 68,
    name: 'Hampshire Hospitals NHS Foundation Trust | RN5',
    crm_code: '17002920888',
    crm_name: 'Hampshire Hospitals NHS Foundation Trust | RN5',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 69,
    name: 'Harrogate And District NHS Foundation Trust | RCD',
    crm_code: '17002920889',
    crm_name: 'Harrogate and District NHS Foundation Trust | RCD',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 70,
    name: 'Health Education And Improvement Wales | 7A9',
    crm_code: '17002921179',
    crm_name: 'Health Education and Improvement Wales | 7A9',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 71,
    name: 'Herefordshire And Worcestershire Health And Care NHS Trust | R1A',
    crm_code: '17002923074',
    crm_name: 'Worcestershire Health and Care NHS Trust | R1A',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 72,
    name: 'Hertfordshire Community NHS Trust | RY4',
    crm_code: '17002923064',
    crm_name: 'Hertfordshire Community NHS Trust | RY4',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 73,
    name: 'Hertfordshire Partnership University NHS Foundation Trust | RWR',
    crm_code: '17002920890',
    crm_name: 'Hertfordshire Partnership University NHS Foundation Trust | RWR',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 74,
    name: 'Homerton Healthcare NHS Foundation Trust | RQX',
    crm_code: '17002920891',
    crm_name: 'Homerton University Hospital NHS Foundation Trust | RQX',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 75,
    name: 'Hounslow And Richmond Community Healthcare NHS Trust | RY9',
    crm_code: '17002923065',
    crm_name: 'Hounslow and Richmond Community Healthcare NHS Trust | RY9',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 76,
    name: 'Hull University Teaching Hospitals NHS Trust | RWA',
    crm_code: '17002920892',
    crm_name: 'Hull University Teaching Hospitals NHS Trust | RWA',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 77,
    name: 'Humber Teaching NHS Foundation Trust | RV9',
    crm_code: '17002920893',
    crm_name: 'Humber NHS Foundation Trust | RV9',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 78,
    name: 'Hywel Dda University Lhb | 7A2',
    crm_code: '17002920894',
    crm_name: 'Hywel Dda University LHB | 7A2',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 79,
    name: 'Imperial College Healthcare NHS Trust | RYJ',
    crm_code: '17002920895',
    crm_name: 'Imperial College Healthcare NHS Trust | RYJ',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 80,
    name: 'Isle Of Wight NHS Trust | R1F',
    crm_code: '17002920896',
    crm_name: 'Isle of Wight NHS Trust | R1F',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 81,
    name: 'James Paget University Hospitals NHS Foundation Trust | RGP',
    crm_code: '17002920897',
    crm_name: 'James Paget University Hospitals NHS Foundation Trust | RGP',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 82,
    name: 'Kent And Medway NHS And Social Care Partnership Trust | RXY',
    crm_code: '17002920898',
    crm_name: 'Kent and Medway NHS and Social Care Partnership Trust | RXY',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 83,
    name: 'Kent Community Health NHS Foundation Trust | RYY',
    crm_code: '17002923066',
    crm_name: 'Kent Community Health NHS Foundation Trust | RYY',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 84,
    name: 'Kettering General Hospital NHS Foundation Trust | RNQ',
    crm_code: '17002920899',
    crm_name: 'Kettering General Hospital NHS Foundation Trust | RNQ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 85,
    name: "KING'S COLLEGE HOSPITAL NHS FOUNDATION TRUST | RJZ",
    crm_code: '17002920900',
    crm_name: "King's College Hospital NHS Foundation Trust | RJZ",
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 86,
    name: 'Kingston Hospital NHS Foundation Trust | RAX',
    crm_code: '17002920901',
    crm_name: 'Kingston Hospital NHS Foundation Trust | RAX',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 87,
    name: 'Lancashire & South Cumbria NHS Foundation Trust | RW5',
    crm_code: '17002920902',
    crm_name: 'Lancashire and South Cumbria NHS Foundation Trust | RW5',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 88,
    name: 'Lancashire Teaching Hospitals NHS Foundation Trust | RXN',
    crm_code: '17002920903',
    crm_name: 'Lancashire Teaching Hospitals NHS Foundation Trust | RXN',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 89,
    name: 'Leeds And York Partnership NHS Foundation Trust | RGD',
    crm_code: '17002920904',
    crm_name: 'Leeds and York Partnership NHS Foundation Trust | RGD',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 90,
    name: 'Leeds Community Healthcare NHS Trust | RY6',
    crm_code: '17002923067',
    crm_name: 'Leeds Community Healthcare NHS Trust | RY6',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 91,
    name: 'Leeds Teaching Hospitals NHS Trust | RR8',
    crm_code: '17002920905',
    crm_name: 'Leeds Teaching Hospitals NHS Trust | RR8',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 92,
    name: 'Leicestershire Partnership NHS Trust | RT5',
    crm_code: '17002920906',
    crm_name: 'Leicestershire Partnership NHS Trust | RT5',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 93,
    name: 'Lewisham And Greenwich NHS Trust | RJ2',
    crm_code: '17002920907',
    crm_name: 'Lewisham and Greenwich NHS Trust | RJ2',
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 94,
    name: 'Lincolnshire Community Health Services NHS Trust | RY5',
    crm_code: '17002923068',
    crm_name: 'Lincolnshire Community Health Services NHS Trust | RY5',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 95,
    name: 'Lincolnshire Partnership NHS Foundation Trust | RP7',
    crm_code: '17002920908',
    crm_name: 'Lincolnshire Partnership NHS Foundation Trust | RP7',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 96,
    name: 'Liverpool Heart And Chest Hospital NHS Foundation Trust | RBQ',
    crm_code: '17002920909',
    crm_name: 'Liverpool Heart and Chest Hospital NHS Foundation Trust | RBQ',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 97,
    name: 'Liverpool University Hospitals NHS Foundation Trust | REM',
    crm_code: '17002920955',
    crm_name: 'Liverpool University Hospitals NHS Foundation Trust | REM',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 98,
    name: "LIVERPOOL WOMEN'S NHS FOUNDATION TRUST | REP",
    crm_code: '17002920910',
    crm_name: "Liverpool Women's NHS Foundation Trust | REP",
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 99,
    name: 'London Ambulance Service NHS Trust | RRU',
    crm_code: '17002920911',
    crm_name: 'London Ambulance Service NHS Trust | RRU',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 100,
    name: 'London North West University Healthcare NHS Trust | R1K',
    crm_code: '17002920912',
    crm_name: 'London North West University Healthcare NHS Trust | R1K',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 101,
    name: 'Maidstone And Tunbridge Wells NHS Trust | RWF',
    crm_code: '17002920914',
    crm_name: 'Maidstone and Tunbridge Wells NHS Trust | RWF',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 102,
    name: 'Manchester University NHS Foundation Trust | R0A',
    crm_code: '17002920820',
    crm_name: 'Manchester University NHS Foundation Trust | R0A',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 103,
    name: 'Medway NHS Foundation Trust | RPA',
    crm_code: '17002920915',
    crm_name: 'Medway NHS Foundation Trust | RPA',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 104,
    name: 'Mersey Care NHS Foundation Trust | RW4',
    crm_code: '17002920916',
    crm_name: 'Mersey Care NHS Foundation Trust | RW4',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 105,
    name: 'Mid And South Essex NHS Foundation Trust | RAJ',
    crm_code: '17002920977',
    crm_name: 'Mid and South Essex NHS Foundation Trust | RAJ',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 106,
    name: 'Mid Cheshire Hospitals NHS Foundation Trust | RBT',
    crm_code: '17002920917',
    crm_name: 'Mid Cheshire Hospitals NHS Foundation Trust | RBT',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 107,
    name: 'Mid Yorkshire Hospitals NHS Trust | RXF',
    crm_code: '17002920918',
    crm_name: 'Mid Yorkshire Hospitals NHS Trust | RXF',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 108,
    name: 'Midlands Partnership NHS Foundation Trust | RRE',
    crm_code: '17002920971',
    crm_name: 'Midlands Partnership NHS Foundation Trust | RRE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 109,
    name: 'Milton Keynes University Hospital NHS Foundation Trust | RD8',
    crm_code: '17002920919',
    crm_name: 'Milton Keynes University Hospital NHS Foundation Trust | RD8',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 110,
    name: 'Moorfields Eye Hospital NHS Foundation Trust | RP6',
    crm_code: '17002920920',
    crm_name: 'Moorfields Eye Hospital NHS Foundation Trust | RP6',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 111,
    name: 'NHS Greater Glasgow & Clyde | SG1',
    crm_code: '17002921160',
    crm_name: 'NHS Greater Glasgow and Clyde | SG1',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 112,
    name: 'NHS National Services Scotland | SX1',
    crm_code: '17002921167',
    crm_name: 'NHS National Services Scotland | SX1',
    applications_subscription: 'Price Benchmarking (PB), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 113,
    name: 'NHS Wales Shared Services Partnership (Nwssp) | WAL',
    crm_code: '17003020176',
    crm_name: 'NHS Wales Shared Services Partnership | WAL',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 114,
    name: 'Norfolk And Norwich University Hospitals NHS Foundation Trust | RM1',
    crm_code: '17002920921',
    crm_name:
      'Norfolk and Norwich University Hospitals NHS Foundation Trust | RM1',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 115,
    name: 'Norfolk And Suffolk NHS Foundation Trust | RMY',
    crm_code: '17002920922',
    crm_name: 'Norfolk and Suffolk NHS Foundation Trust | RMY',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 116,
    name: 'Norfolk Community Health And Care NHS Trust | RY3',
    crm_code: '17002923069',
    crm_name: 'Norfolk Community Health and Care NHS Trust | RY3',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 117,
    name: 'North Bristol NHS Trust | RVJ',
    crm_code: '17002920923',
    crm_name: 'North Bristol NHS Trust | RVJ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 118,
    name: 'North Cumbria Integrated Care NHS Foundation Trust | RNN',
    crm_code: '17002920861',
    crm_name: 'North Cumbria Integrated Care NHS Foundation Trust | RNN',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 119,
    name: 'North East Ambulance Service NHS Foundation Trust | RX6',
    crm_code: '17002920924',
    crm_name: 'North East Ambulance Service NHS Foundation Trust | RX6',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 120,
    name: 'North East London NHS Foundation Trust | RAT',
    crm_code: '17002920925',
    crm_name: 'North East London NHS Foundation Trust | RAT',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 121,
    name: 'North Middlesex University Hospital NHS Trust | RAP',
    crm_code: '17002920926',
    crm_name: 'North Middlesex University Hospital NHS Trust | RAP',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 122,
    name: 'North Staffordshire Combined Healthcare NHS Trust | RLY',
    crm_code: '17002920927',
    crm_name: 'North Staffordshire Combined Healthcare NHS Trust | RLY',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 123,
    name: 'North Tees And Hartlepool NHS Foundation Trust | RVW',
    crm_code: '17002920928',
    crm_name: 'North Tees and Hartlepool NHS Foundation Trust | RVW',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 124,
    name: 'North West Ambulance Service NHS Trust | RX7',
    crm_code: '17002920929',
    crm_name: 'North West Ambulance Service NHS Trust | RX7',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 125,
    name: 'North West Anglia NHS Foundation Trust | RGN',
    crm_code: '17002920822',
    crm_name: 'North West Anglia NHS Foundation Trust | RGN',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 126,
    name: 'North West Boroughs Healthcare NHS Foundation Trust | RTV',
    crm_code: '17002921049',
    crm_name: 'North West Boroughs Healthcare NHS Foundation Trust | RTV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 127,
    name: 'Northampton General Hospital NHS Trust | RNS',
    crm_code: '17002920930',
    crm_name: 'Northampton General Hospital NHS Trust | RNS',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 128,
    name: 'Northamptonshire Healthcare NHS Foundation Trust | RP1',
    crm_code: '17002920931',
    crm_name: 'Northamptonshire Healthcare NHS Foundation Trust | RP1',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 129,
    name: 'Northern Care Alliance NHS Foundation Trust | RM3',
    crm_code: '17002920959',
    crm_name: 'Salford Royal NHS Foundation Trust | RM3',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 130,
    name: 'Northern Devon Healthcare NHS Trust | RBZ',
    crm_code: '17002920932',
    crm_name: 'Northern Devon Healthcare NHS Trust | RBZ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 131,
    name: 'Northern Heath & Social Care Trust | ZT2',
    crm_code: '17002921171',
    crm_name: 'Northern Heath & Social Care Trust | ZT2',
    applications_subscription: 'Price Benchmarking (PB), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 132,
    name: 'Northern Lincolnshire And Goole NHS Foundation Trust | RJL',
    crm_code: '17002920933',
    crm_name: 'Northern Lincolnshire and Goole NHS Foundation Trust | RJL',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 133,
    name: 'Northumbria Healthcare NHS Foundation Trust | RTF',
    crm_code: '17002920935',
    crm_name: 'Northumbria Healthcare NHS Foundation Trust | RTF',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 134,
    name: 'Nottingham University Hospitals NHS Trust | RX1',
    crm_code: '17002920936',
    crm_name: 'Nottingham University Hospitals NHS Trust | RX1',
    applications_subscription:
      'Procurement Dashboard (PD), Platform, Catalogue Manage (Heavy)',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 135,
    name: 'Nottinghamshire Healthcare NHS Foundation Trust | RHA',
    crm_code: '17002920937',
    crm_name: 'Nottinghamshire Healthcare NHS Foundation Trust | RHA',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 136,
    name: 'OTHER',
    crm_code: '',
    crm_name: 'Other',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 137,
    name: 'Oxford Health NHS Foundation Trust | RNU',
    crm_code: '17002920938',
    crm_name: 'Oxford Health NHS Foundation Trust | RNU',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 138,
    name: 'Oxford University Hospitals NHS Foundation Trust | RTH',
    crm_code: '17002920939',
    crm_name: 'Oxford University Hospitals NHS Foundation Trust | RTH',
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 139,
    name: 'Oxleas NHS Foundation Trust | RPG',
    crm_code: '17002920940',
    crm_name: 'Oxleas NHS Foundation Trust | RPG',
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 140,
    name: 'Pennine Acute Hospitals NHS Trust | RW6',
    crm_code: '17002920942',
    crm_name: 'Pennine Acute Hospitals NHS Trust | RW6',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 141,
    name: 'Pennine Care NHS Foundation Trust | RT2',
    crm_code: '17002920943',
    crm_name: 'Pennine Care NHS Foundation Trust | RT2',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 142,
    name: 'Poole Hospital NHS Foundation Trust | RD3',
    crm_code: '17002921064',
    crm_name: 'Poole Hospital NHS Foundation Trust | RD3',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 143,
    name: 'Portsmouth Hospitals University National Health Service Trust | RHU',
    crm_code: '17002920945',
    crm_name: 'Portsmouth Hospitals University NHS Trust | RHU',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 144,
    name: 'Powys Teaching Lhb | 7A7',
    crm_code: '17002920946',
    crm_name: 'Powys Teaching LHB | 7A7',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 145,
    name: 'Public Health Wales NHS Trust | RYT',
    crm_code: '17002920947',
    crm_name: 'Public Health Wales NHS Trust | RYT',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 146,
    name: 'Queen Victoria Hospital NHS Foundation Trust | RPC',
    crm_code: '17002920948',
    crm_name: 'Queen Victoria Hospital NHS Foundation Trust | RPC',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 147,
    name: 'Rotherham Doncaster And South Humber NHS Foundation Trust | RXE',
    crm_code: '17002920949',
    crm_name: 'Rotherham Doncaster and South Humber NHS Foundation Trust | RXE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 148,
    name: 'Royal Berkshire NHS Foundation Trust | RHW',
    crm_code: '17002920950',
    crm_name: 'Royal Berkshire NHS Foundation Trust | RHW',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 149,
    name: 'Royal Brompton & Harefield NHS Foundation Trust | RT3',
    crm_code: '17002920951',
    crm_name: 'Royal Brompton & Harefield NHS Foundation Trust | RT3',
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 150,
    name: 'Royal Cornwall Hospitals NHS Trust | REF',
    crm_code: '17002920952',
    crm_name: 'Royal Cornwall Hospitals NHS Trust | REF',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 151,
    name: 'Royal Devon University Healthcare NHS Foundation Trust | RH8',
    crm_code: '17002920953',
    crm_name: 'Royal Devon and Exeter NHS Foundation Trust | RH8',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 152,
    name: 'Royal Free London NHS Foundation Trust | RAL',
    crm_code: '17002920954',
    crm_name: 'Royal Free London NHS Foundation Trust | RAL',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 153,
    name: 'Royal National Orthopaedic Hospital NHS Trust | RAN',
    crm_code: '17002920956',
    crm_name: 'Royal National Orthopaedic Hospital NHS Trust | RAN',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 154,
    name: 'Royal Papworth Hospital NHS Foundation Trust | RGM',
    crm_code: '17002920941',
    crm_name: 'Royal Papworth Hospital NHS Foundation Trust | RGM',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 155,
    name: 'Royal Surrey County Hospital NHS Foundation Trust | RA2',
    crm_code: '17002920957',
    crm_name: 'Royal Surrey County Hospital NHS Foundation Trust | RA2',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 156,
    name: 'Royal United Hospitals Bath NHS Foundation Trust | RD1',
    crm_code: '17002920958',
    crm_name: 'Royal United Hospitals Bath NHS Foundation Trust | RD1',
    applications_subscription:
      'Procurement Dashboard+ (PD+), Platform, Catalogue Assign (Medium)',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 157,
    name: 'Salisbury NHS Foundation Trust | RNZ',
    crm_code: '17002920960',
    crm_name: 'Salisbury NHS Foundation Trust | RNZ',
    applications_subscription:
      'Procurement Dashboard+ (PD+), Platform, Catalogue Assign (Medium)',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 158,
    name: 'Sandwell And West Birmingham Hospitals NHS Trust | RXK',
    crm_code: '17002920961',
    crm_name: 'Sandwell and West Birmingham Hospitals NHS Trust | RXK',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 159,
    name: "SHEFFIELD CHILDREN'S NHS FOUNDATION TRUST | RCU",
    crm_code: '17002920962',
    crm_name: "Sheffield Children's NHS Foundation Trust | RCU",
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 160,
    name: 'Sheffield Teaching Hospitals NHS Foundation Trust | RHQ',
    crm_code: '17002920964',
    crm_name: 'Sheffield Teaching Hospitals NHS Foundation Trust | RHQ',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 161,
    name: 'Sherwood Forest Hospitals NHS Foundation Trust | RK5',
    crm_code: '17002920965',
    crm_name: 'Sherwood Forest Hospitals NHS Foundation Trust | RK5',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 162,
    name: 'Shropshire Community Health NHS Trust | R1D',
    crm_code: '17002923070',
    crm_name: 'Shropshire Community Health NHS Trust | R1D',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 163,
    name: 'Solent NHS Trust | R1C',
    crm_code: '17002923071',
    crm_name: 'Solent NHS Trust | R1C',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 164,
    name: 'Somerset NHS Foundation Trust | RH5',
    crm_code: '17002920967',
    crm_name: 'Somerset NHS Foundation Trust | RH5',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 165,
    name: 'South Central Ambulance Service NHS Foundation Trust | RYE',
    crm_code: '17002920968',
    crm_name: 'South Central Ambulance Service NHS Foundation Trust | RYE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 166,
    name: 'South East Coast Ambulance Service NHS Foundation Trust | RYD',
    crm_code: '17002920969',
    crm_name: 'South East Coast Ambulance Service NHS Foundation Trust | RYD',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 167,
    name: 'South Eastern Health & Social Care | ZT4',
    crm_code: '17002921173',
    crm_name: 'South Eastern Health & Social Care | ZT4',
    applications_subscription: 'Price Benchmarking (PB), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 168,
    name: 'South London And Maudsley NHS Foundation Trust | RV5',
    crm_code: '17002920970',
    crm_name: 'South London and Maudsley NHS Foundation Trust | RV5',
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 169,
    name: 'South Tees Hospitals NHS Foundation Trust | RTR',
    crm_code: '17002920972',
    crm_name: 'South Tees Hospitals NHS Foundation Trust | RTR',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 170,
    name: 'South Tyneside And Sunderland NHS Foundation Trust | R0B',
    crm_code: '17002920819',
    crm_name: 'South Tyneside and Sunderland NHS Foundation Trust | R0B',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 171,
    name: 'South Warwickshire University NHS Foundation Trust | RJC',
    crm_code: '17002920973',
    crm_name: 'South Warwickshire NHS Foundation Trust | RJC',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 172,
    name: "SOUTH WEST LONDON AND ST GEORGE'S MENTAL HEALTH NHS TRUST | RQY",
    crm_code: '17002920974',
    crm_name: "South West London and St George's Mental Health NHS Trust | RQY",
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 173,
    name: 'South West Yorkshire Partnership NHS Foundation Trust | RXG',
    crm_code: '17002920975',
    crm_name: 'South West Yorkshire Partnership NHS Foundation Trust | RXG',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 174,
    name: 'South Western Ambulance Service NHS Foundation Trust | RYF',
    crm_code: '17002920976',
    crm_name: 'South Western Ambulance Service NHS Foundation Trust | RYF',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 175,
    name: 'Southern Health & Social Care Trust | ZT3',
    crm_code: '17002921172',
    crm_name: 'Southern Health & Social Care Trust | ZT3',
    applications_subscription: 'Price Benchmarking (PB), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 176,
    name: 'Southern Health NHS Foundation Trust | RW1',
    crm_code: '17002920978',
    crm_name: 'Southern Health NHS Foundation Trust | RW1',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 178,
    name: "ST GEORGE'S UNIVERSITY HOSPITALS NHS FOUNDATION TRUST | RJ7",
    crm_code: '17002920980',
    crm_name: "St George's University Hospitals NHS Foundation Trust | RJ7",
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 180,
    name: 'Stockport NHS Foundation Trust | RWJ',
    crm_code: '17002920982',
    crm_name: 'Stockport NHS Foundation Trust | RWJ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 181,
    name: 'Surrey And Borders Partnership NHS Foundation Trust | RXX',
    crm_code: '17002920983',
    crm_name: 'Surrey and Borders Partnership NHS Foundation Trust | RXX',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 182,
    name: 'Surrey And Sussex Healthcare NHS Trust | RTP',
    crm_code: '17002920984',
    crm_name: 'Surrey and Sussex Healthcare NHS Trust | RTP',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 183,
    name: 'Sussex Community NHS Foundation Trust | RDR',
    crm_code: '17002923072',
    crm_name: 'Sussex Community NHS Foundation Trust | RDR',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 184,
    name: 'Sussex Partnership NHS Foundation Trust | RX2',
    crm_code: '17002920985',
    crm_name: 'Sussex Partnership NHS Foundation Trust | RX2',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 185,
    name: 'Swansea Bay University Lhb | 7A3',
    crm_code: '17002920825',
    crm_name: 'Swansea Bay University LHB | 7A3',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 186,
    name: 'Tameside And Glossop Integrated Care NHS Foundation Trust | RMP',
    crm_code: '17002920986',
    crm_name: 'Tameside and Glossop Integrated Care NHS Foundation Trust | RMP',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 187,
    name: 'Tavistock And Portman NHS Foundation Trust | RNK',
    crm_code: '17002920987',
    crm_name: 'Tavistock and Portman NHS Foundation Trust | RNK',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 188,
    name: 'Tees, Esk And Wear Valleys NHS Foundation Trust | RX3',
    crm_code: '17002920988',
    crm_name: 'Tees, Esk and Wear Valleys NHS Foundation Trust | RX3',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 189,
    name: 'The Christie NHS Foundation Trust | RBV',
    crm_code: '17002920989',
    crm_name: 'The Christie NHS Foundation Trust | RBV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 190,
    name: 'The Clatterbridge Cancer Centre NHS Foundation Trust | REN',
    crm_code: '17002920990',
    crm_name: 'The Clatterbridge Cancer Centre NHS Foundation Trust | REN',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 191,
    name: 'The Dudley Group NHS Foundation Trust | RNA',
    crm_code: '17002920992',
    crm_name: 'The Dudley Group NHS Foundation Trust | RNA',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 192,
    name: 'The Hillingdon Hospitals NHS Foundation Trust | RAS',
    crm_code: '17002920993',
    crm_name: 'The Hillingdon Hospitals NHS Foundation Trust | RAS',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 193,
    name: 'The Newcastle Upon Tyne Hospitals NHS Foundation Trust | RTD',
    crm_code: '17002920994',
    crm_name: 'The Newcastle Upon Tyne Hospitals NHS Foundation Trust | RTD',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 194,
    name: 'The Princess Alexandra Hospital NHS Trust | RQW',
    crm_code: '17002920995',
    crm_name: 'The Princess Alexandra Hospital NHS Trust | RQW',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 195,
    name: "THE QUEEN ELIZABETH HOSPITAL, KING'S LYNN, NHS FOUNDATION TRUST | RCX",
    crm_code: '17002920996',
    crm_name:
      "The Queen Elizabeth Hospital, King's Lynn, NHS Foundation Trust | RCX",
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 196,
    name: 'The Robert Jones And Agnes Hunt Orthopaedic Hospital NHS Foundation Trust | RL1',
    crm_code: '17002920997',
    crm_name:
      'The Robert Jones and Agnes Hunt Orthopaedic Hospital NHS Foundation Trust | RL1',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 197,
    name: 'The Rotherham NHS Foundation Trust | RFR',
    crm_code: '17002920998',
    crm_name: 'The Rotherham NHS Foundation Trust | RFR',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 198,
    name: 'The Royal Bournemouth And Christchurch Hospitals NHS Foundation Trust | RDZ',
    crm_code: '17002921063',
    crm_name:
      'The Royal Bournemouth and Christchurch Hospitals NHS Foundation Trust | RDZ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 199,
    name: 'The Royal Marsden NHS Foundation Trust | RPY',
    crm_code: '17002920999',
    crm_name: 'The Royal Marsden NHS Foundation Trust | RPY',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 200,
    name: 'The Royal Orthopaedic Hospital NHS Foundation Trust | RRJ',
    crm_code: '17002921000',
    crm_name: 'The Royal Orthopaedic Hospital NHS Foundation Trust | RRJ',
    applications_subscription:
      'Procurement Dashboard+ (PD+), Platform, Catalogue Check (Lite), Workplan',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 201,
    name: 'The Royal Wolverhampton NHS Trust | RL4',
    crm_code: '17002921001',
    crm_name: 'The Royal Wolverhampton NHS Trust | RL4',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 202,
    name: 'The Shrewsbury And Telford Hospital NHS Trust | RXW',
    crm_code: '17002920966',
    crm_name: 'Shrewsbury and Telford Hospital NHS Trust | RXW',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 203,
    name: 'The Walton Centre NHS Foundation Trust | RET',
    crm_code: '17002921002',
    crm_name: 'The Walton Centre NHS Foundation Trust | RET',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 204,
    name: 'Torbay And South Devon NHS Foundation Trust | RA9',
    crm_code: '17002921004',
    crm_name: 'Torbay and South Devon NHS Foundation Trust | RA9',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 205,
    name: 'United Lincolnshire Hospitals NHS Trust | RWD',
    crm_code: '17002921005',
    crm_name: 'United Lincolnshire Hospitals NHS Trust | RWD',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 206,
    name: 'University College London Hospitals NHS Foundation Trust | RRV',
    crm_code: '17002921006',
    crm_name: 'University College London Hospitals NHS Foundation Trust | RRV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 207,
    name: 'University Hospital Southampton NHS Foundation Trust | RHM',
    crm_code: '17002921007',
    crm_name: 'University Hospital Southampton NHS Foundation Trust | RHM',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 208,
    name: 'University Hospitals Birmingham NHS Foundation Trust | RRK',
    crm_code: '17002921008',
    crm_name: 'University Hospitals Birmingham NHS Foundation Trust | RRK',
    applications_subscription:
      'Procurement Dashboard+ (PD+), Platform, Catalogue Check (Lite), Workplan',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 209,
    name: 'University Hospitals Bristol And Weston NHS Foundation Trust | RA7',
    crm_code: '17002921009',
    crm_name: 'University Hospitals Bristol NHS Foundation Trust | RA7',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 210,
    name: 'University Hospitals Coventry And Warwickshire NHS Trust | RKB',
    crm_code: '17002921010',
    crm_name: 'University Hospitals Coventry and Warwickshire NHS Trust | RKB',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 211,
    name: 'University Hospitals Dorset NHS Foundation Trust | R0D',
    crm_code: '17002920823',
    crm_name: 'University Hospitals Dorset NHS Foundation Trust | R0D',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 212,
    name: 'University Hospitals Of Derby And Burton NHS Foundation Trust | RTG',
    crm_code: '17002920864',
    crm_name:
      'University Hospitals of Derby and Burton NHS Foundation Trust | RTG',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 213,
    name: 'University Hospitals Of Leicester NHS Trust | RWE',
    crm_code: '17002921011',
    crm_name: 'University Hospitals of Leicester NHS Trust | RWE',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 214,
    name: 'University Hospitals Of Morecambe Bay NHS Foundation Trust | RTX',
    crm_code: '17002921012',
    crm_name:
      'University Hospitals of Morecambe Bay NHS Foundation Trust | RTX',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 215,
    name: 'University Hospitals Of North Midlands NHS Trust | RJE',
    crm_code: '17002921013',
    crm_name: 'University Hospitals of North Midlands NHS Trust | RJE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 216,
    name: 'University Hospitals Plymouth NHS Trust | RK9',
    crm_code: '17002920944',
    crm_name: 'University Hospitals Plymouth NHS Trust | RK9',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 217,
    name: 'University Hospitals Sussex NHS Foundation Trust | RYR',
    crm_code: '17002921023',
    crm_name: 'Western Sussex Hospitals NHS Foundation Trust | RYR',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 218,
    name: 'Velindre NHS Trust | RQF',
    crm_code: '17002921014',
    crm_name: 'Velindre NHS Trust | RQF',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 219,
    name: 'Walsall Healthcare NHS Trust | RBK',
    crm_code: '17002921015',
    crm_name: 'Walsall Healthcare NHS Trust | RBK',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 220,
    name: 'Warrington And Halton Teaching Hospitals NHS Foundation Trust | RWW',
    crm_code: '17002921016',
    crm_name: 'Warrington and Halton Hospitals NHS Foundation Trust | RWW',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 221,
    name: 'Welsh Ambulance Services NHS Trust | RT4',
    crm_code: '17002921017',
    crm_name: 'Welsh Ambulance Services NHS Trust | RT4',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 222,
    name: 'Welsh Blood Service | 7B1',
    crm_code: '17002921180',
    crm_name: 'Welsh Blood Service | 7B1',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 223,
    name: 'Welsh Health Specialist Services Committee | 7A8',
    crm_code: '17002921018',
    crm_name: 'Welsh Health Specialist Services Committee | 7A8',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 224,
    name: 'West Hertfordshire Teaching Hospitals NHS Trust | RWG',
    crm_code: '17002921019',
    crm_name: 'West Hertfordshire Hospitals NHS Trust | RWG',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 225,
    name: 'West London NHS Trust | RKL',
    crm_code: '17002921020',
    crm_name: 'West London NHS Trust | RKL',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 226,
    name: 'West Midlands Ambulance Service University NHS Foundation Trust | RYA',
    crm_code: '17002921021',
    crm_name: 'West Midlands Ambulance Service NHS Foundation Trust | RYA',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 227,
    name: 'West Suffolk NHS Foundation Trust | RGR',
    crm_code: '17002921022',
    crm_name: 'West Suffolk NHS Foundation Trust | RGR',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 228,
    name: 'Western Health & Social Care Trust | ZT5',
    crm_code: '17002921174',
    crm_name: 'Western Health & Social Care Trust | ZT5',
    applications_subscription: 'Price Benchmarking (PB), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 229,
    name: 'Whittington Health NHS Trust | RKE',
    crm_code: '17002921003',
    crm_name: 'The Whittington Hospital NHS Trust | RKE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 230,
    name: 'Wirral Community Health And Care NHS Foundation Trust | RY7',
    crm_code: '17002923073',
    crm_name: 'Wirral Community Health and Care NHS Foundation Trust | RY7',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 231,
    name: 'Wirral University Teaching Hospital NHS Foundation Trust | RBL',
    crm_code: '17002921024',
    crm_name: 'Wirral University Teaching Hospital NHS Foundation Trust | RBL',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 232,
    name: 'Worcestershire Acute Hospitals NHS Trust | RWP',
    crm_code: '17002921025',
    crm_name: 'Worcestershire Acute Hospitals NHS Trust | RWP',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 233,
    name: 'Wrightington, Wigan And Leigh NHS Foundation Trust | RRF',
    crm_code: '17002921026',
    crm_name: 'Wrightington, Wigan and Leigh NHS Foundation Trust | RRF',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 234,
    name: 'Wye Valley NHS Trust | RLQ',
    crm_code: '17002921027',
    crm_name: 'Wye Valley NHS Trust | RLQ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 235,
    name: 'Simply Serve | RA4',
    crm_code: '17005859793',
    crm_name: 'Simply Serve',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 236,
    name: 'York And Scarborough Teaching Hospitals NHS Foundation Trust | RCB',
    crm_code: '17002921029',
    crm_name: 'York Teaching Hospital NHS Foundation Trust | RCB',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 237,
    name: 'Yorkshire Ambulance Service NHS Trust | RX8',
    crm_code: '17002921030',
    crm_name: 'Yorkshire Ambulance Service NHS Trust | RX8',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 238,
    name: 'Business Services Organisation - Procurement And Logistics Service (Bso PALS)',
    crm_code: '',
    crm_name:
      'Business Services Organisation - Procurement and Logistics Service (BSO PALS)',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 239,
    name: 'Mersey And West Lancashire Teaching Hospitals NHS Trust | RBN',
    crm_code: '',
    crm_name: 'Mersey and West Lancashire Teaching Hospitals NHS Trust | RBN',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 240,
    name: 'NHS Dorset Icb | QVV',
    crm_code: '17003019986',
    crm_name: 'NHS Dorset ICB | QVV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
];
